import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import Login from './pages/Login'
import DashboardAdmin from './pages/DashboardAdmin'
import NavbarHome from './components/NavbarHome'
import { useContext, useEffect, useState } from 'react';
import { UserContext } from './context/UserProvider';
import PageNotFound from './pages/PageNotFound';
import Profile from './pages/Profile'
import Register from './pages/Register';
import ManageUsers from './pages/ManageUsers';
import DashboardUser from './pages/DashboardUser';
import Footer from './components/Footer';
import Subscriptions from './components/Subscriptions';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import TransactionCancelled from './pages/TransactionCancelled';
import TransactionSucceeded from './pages/TransactionSucceeded';
import UpgradeSubscription from './pages/UpgradeSubscription';
import UpgradeSucceeded from './pages/UpgradeSucceeded';
import UpgradeFromFreeSucceeded from './pages/UpgradeFromFreeSucceeded';
import UpgradeCancelled from './pages/UpgradeFailed';
import ProtectedAxios from './api/protectedAxios';
import TopupCancelled from './pages/Transactions/TopupCancelled';
import TopupSuccess from './pages/Transactions/TopupSuccess';
import ToastNotifications from './components/ToastNotifications';
import io from 'socket.io-client';
import { toast } from 'react-hot-toast';
import SharedChatsPrompt from './pages/SharedChatsPrompt';
import Chat from './pages/Chat';
import SendPasswordResetCode from './pages/SendPasswordResetCode';
import ResetPassword from './pages/ResetPassword';
import AddEnterpriseUser from './pages/Admin/AddEnterpriseUser';
import EnterpriseJoin from './pages/EnterpriseJoin';
import SetPaymentMethod from './components/SetPaymentMethod';
import SetupIntentSucceeded from './pages/Transactions/SetupIntentSucceeded';
import SetupIntentFailed from './pages/Transactions/SetupIntentFailed';
import EnterpriseChildJoin from './pages/EnterpriseChildJoin';
import Axios from './api/axios';
import ManageEnterpriseChildAccounts from './pages/ManageEnterpriseChildAccounts';
import OrganizationChatView from './pages/OrganizationChatView';
import MyTemplates from './pages/MyTemplates';
import { SocketContext } from './context/SocketProvider';
import ManageModels from './pages/Admin/ManageModels';
import ScrollToTop from './components/ScrollToTop';
import TemplateMarketplace from './pages/TemplateMarketplace';
import StartChat from './pages/StartChat';
import ManageExplorerPrompts from './pages/ManageExplorerPrompts';
import AdminTools from './pages/AdminTools';
import SharedChatView from './pages/SharedChatView';
import ContinueSharedChat from './pages/ContinueSharedChat';
import AccessKeys from './pages/AccessKeys';
import AddUser from './pages/AddUser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CreateUsername from './pages/CreateUsername';
import EmailTemplates from './pages/EmailTemplates';
import ManagePublicTemplates from './pages/ManagePublicTemplates';
import UserStats from './pages/Admin/UserStats';
import AppStats from './pages/Admin/AppStats';
import User from './pages/User';
import ViewTemplate from './pages/ViewTemplate';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // starts with pk_

function App() {
  const [user, setUser, isLoggedIn] = useContext(UserContext)
  const [mySocket, setMySocket] = useContext(SocketContext)
  let mounted = false
  const handleRefresh = (location) => {
    setTimeout(() => {
      if (!location) {
        window.location.reload()
      } else {
        window.location.href = location
      }
    }, 2000)
  }

  useEffect(() => {
    if (!mounted) {
      mounted = true
      if (user.user_id !== undefined) {
        let socket = io(process.env.REACT_APP_BASE_URL, {
          transports: ["polling"],
          query: {
            id: user.stripe_customer_id ? user.stripe_customer_id : user.user_id
          }
        });

        socket.on('plan-upgraded-by-admin', (data) => {
          toast.success(`You are upgraded to Standard plan till ${new Date(data.endDate).toLocaleDateString("en-GB")} from SageCollab`, { duration: Infinity })
          refreshSubscriptionDetails()
          handleRefresh()
        })

        socket.on('upgraded-plan-by-admin-over', (data) => {
          refreshSubscriptionDetails()
          toast("Your Standard plan trial has expired. Upgrade to keep using the Standard plan features", {
            icon: "⚠️",
            duration: Infinity
          })
          handleRefresh("upgrade")
        })

        socket.on('standard-subscription-cancelled-downgraded-to-free', (data) => {
          refreshSubscriptionDetails()
          setUser({ ...user, stripe_subscription_id: data.subscription_id })
          toast("Your Standard plan has expired and you are downgraded to Free plan. Upgrade now to keep using features of Standard plan.", {
            icon: "⚠️",
            duration: Infinity
          })
          handleRefresh("upgrade")
        })

        socket.on('hook-test', (data) => {
        })

        socket.on('free-enterprise-subscription-by-admin', (data) => {
          setUser({ ...user, price_id: data.new_price_id, role_id: data.new_role_id, plans_model_shown: true })
          toast('You are upgraded to Enterprise subscription from SaveMyChat', { icon: "⬆️", duration: Infinity })
        })

        socket.on('account-disabled-by-enterprise-admin', (data) => {
          toast(`Your account is disabled by your organization's admin. Contact your admin for further assistance.`, { id: 'account-disabled', icon: "⚠️", duration: Infinity })
          setTimeout(() => {
            setUser({
              accessToken: '',
              refreshToken: '',
              name: '',
              email: '',
              role_id: ''
            })
          }, 2000)
        })

        socket.on('auto-logout', (data) => {
          setUser({
            accessToken: '',
            refreshToken: '',
            name: '',
            email: '',
            role_id: ''
          })
          toast("Your account was suspended by SageCollab", { icon: "⚠️" })

          setTimeout(() => {
            window.location.href = "/"
          }, 2000)
        })

        socket.on('access-token-updated', (data) => {
          setUser({ ...user, accessToken: data.new_access_token })
        })

        socket.on('template_creator_status_updated', (data) => {
          setUser({ ...user, is_official_template_creator: data.updated_access })
        })

        socket.on('enterprise-subscription-added', (data) => {
        })

        setMySocket(socket)
      }
    }
  }, [user])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      document.title = 'SageCollab - Development'
    }
    else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      document.title = 'SageCollab - Staging'
    }
    else {
      document.title = 'SageCollab'
    }
  }, [])

  //was some re-rendering issue with using custom hooks, thats why used this token code here----- :)
  const [tokens, setTokens] = useState(0)
  const [loadingTokens, setLoadingTokens] = useState(false)
  useEffect(() => {
    updateTokens()
  }, [user])
  const updateTokens = () => {
    setLoadingTokens(true)
    Axios.post('/users/getTokens', { user_id: user.user_id })
      .then(res => {
        setTokens(res.data.tokens)
        setLoadingTokens(false)
      })
      .catch(err => {
        if (err.response.status === 500) {
          setLoadingTokens(false)
        }
      })
  }


  //was some re-rendering issue with using custom hooks, thats why used this subscription code here----- :)
  const [subscriptionDetails, setSubscriptionDetails] = useState(null)

  useEffect(() => {
    if (user.stripe_customer_id && user.stripe_customer_id !== user.user_id) { //means will not be called for admins
      fetchSubscriptionDetails()
    }
  }, [user])

  const fetchSubscriptionDetails = () => {
    // if (user.user_id !== undefined) {
    ProtectedAxios.post('/subscription/subscribedPlanDetails', { customer_id: user.stripe_customer_id, user_id: user.user_id, from: 'App.js' })
      .then(res => {
        setSubscriptionDetails(res.data)

      })
      .catch(err => {
        if (err.response.status === 500) {
          // alert(err.response.data.error)
        }
      })
    // }
  }

  const refreshSubscriptionDetails = () => {
    fetchSubscriptionDetails()
  }


  //to check if account is still active (only need to check for enterprise-child accounts)
  useEffect(() => {
    refreshAccountStatus()
    setInterval(() => {
      refreshAccountStatus()
    }, 60000)
  }, [user])
  const refreshAccountStatus = () => {
    if (user.role_id === 5) {
      ProtectedAxios.post('/users/getAccountStatus', { user_id: user.user_id })
        .then(res => {
          if (res.data.status === false) {
            toast(`Your account is disabled by your organization's admin. Contact your admin for further assistance.`, { id: 'account-disabled', icon: "⚠️", duration: Infinity })
            setTimeout(() => {
              setUser({
                accessToken: '',
                refreshToken: '',
                name: '',
                email: '',
                role_id: ''
              })
            }, 2000)
          }
        })
        .catch(err => {
          console.log(err);
          toast.error(err.response.data.error)
        })
    }
  }


  useEffect(()=>{
    const issue01 = localStorage.getItem("sagecollab-001")
    if(!issue01){
      
    }
  }, [])


  return (

    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Router>
            <ScrollToTop />
            <ToastNotifications />

            <NavbarHome tokens={tokens} subscriptionDetails={subscriptionDetails} />
            <Routes>
              {
                isLoggedIn()
                  ?
                  <>
                    {/* ---   COMMON ROUTES FOR LOGGED IN USERS --- */}
                    <>
                      <Route path='/profile' element={<Profile />} />
                      <Route path='/register' element={<Register />} />
                      <Route path='/login' element={<Login />} />
                    </>
                    {/* ---   END OF COMMON ROUTES FOR LOGGED IN USERS --- */}


                    <>
                      {user.role_id === 2 || user.role_id === 4 || user.role_id === 5
                        ?
                        //---   USER ONLY ROUTES ---
                        <>
                          {!user.stripe_subscription_id
                            ?
                            <>
                              <Route path='/' element={<Subscriptions />} />
                              <Route path='/success/:session_id' element={<TransactionSucceeded updateTokens={updateTokens} />} />
                              <Route path='/cancelled' element={<TransactionCancelled />} />
                            </>

                            :
                            user.username
                              ?
                              <>
                                <Route path='/' element={<DashboardUser socket={mySocket} updateTokens={updateTokens} tokens={tokens} />} />
                                <Route path='/start-chat' element={<StartChat socket={mySocket} updateTokens={updateTokens} tokens={tokens} />} />
                                <Route path='/chat/:chat_id' element={<Chat updateTokens={updateTokens} tokens={tokens} />} />
                                <Route path='/shared-chat/:chat_id' element={<SharedChatsPrompt tokens={tokens} />} />
                                <Route path='/template-marketplace' element={<TemplateMarketplace />} />
                                <Route path='/chat/shared/continue/:chat_id' element={<ContinueSharedChat />} />
                                <Route path='/access-keys' element={<AccessKeys />} />
                                {subscriptionDetails?.price_id
                                  &&
                                  <>
                                    {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_A_ID
                                      ?

                                      // FREE user only routes
                                      <Route path='/upgrade' element={<UpgradeSubscription />} />
                                      // END of FREE users only routes

                                      :
                                      <>
                                        {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_B_ID || subscriptionDetails.price_id === process.env.REACT_APP_PRICE_C_ID
                                          &&

                                          // Standard and Enterprise users route only
                                          <>
                                            {/* <Route path='/api-keys' element={<APIKeys />} /> */}
                                          </>
                                          // END of Standard and Enterprise users route only

                                        }
                                      </>
                                    }
                                    {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_C_ID
                                      &&

                                      // Enterprise users route only
                                      <>
                                        <Route path='/organization-chat/:chat_id' element={<OrganizationChatView />} />
                                        <Route path='/manage-accounts' element={<ManageEnterpriseChildAccounts />} />
                                      </>
                                      // END of Enterprise users route only

                                    }
                                  </>
                                }
                                {/* <Route path='/api-keys' element={<APIKeys />} /> */}

                                {/* transaction routes */}
                                <Route path='/cancelled' element={<TransactionCancelled />} />
                                <Route path='/success/:session_id' element={<TransactionSucceeded />} />
                                <Route path='/upgrade-success' element={<UpgradeSucceeded />} />
                                <Route path='/upgrade-success/from-free/:session_id' element={<UpgradeFromFreeSucceeded updateTokens={updateTokens} />} />
                                <Route path='/upgrade-cancelled' element={<UpgradeCancelled />} />
                                <Route path='/topup-success/:session_id' element={<TopupSuccess updateTokens={updateTokens} />} />
                                <Route path='/topup-cancelled/:session_id' element={<TopupCancelled />} />

                                <Route path='/setup-intent/succeeded' element={<SetupIntentSucceeded />} />
                                <Route path='/setup-intent/failed' element={<SetupIntentFailed />} />

                              </>

                              :
                              <Route path='/' element={<CreateUsername />} />
                          }
                        </>
                        //---   END OF USER ONLY ROUTES ---


                        :
                        //---   ADMIN ONLY ROUTES --- 
                        <>
                          <Route path='/' element={<DashboardAdmin />} />
                          <Route path='/manage-users' element={<ManageUsers />} />
                          <Route path='/add-enterprise-user' element={<AddEnterpriseUser />} />
                          <Route path='/manage-models' element={<ManageModels />} />
                          <Route path='/prompt-templates' element={<MyTemplates />} />
                          <Route path='/public-templates' element={<ManagePublicTemplates />} />
                          <Route path='/manage-explorer-prompts' element={<ManageExplorerPrompts />} />
                          <Route path='/user-stats' element={<UserStats />} />
                          <Route path='/app-stats' element={<AppStats />} />
                          <Route path='/email-templates' element={<EmailTemplates />} />
                          <Route path='/tools' element={<AdminTools />} />
                          <Route path='/add-user' element={<AddUser />} />
                          {/* <Route path='/manage-enterprise-accounts' element={<ManageEnterpriseAccounts />} /> */}
                        </>
                        //---   END OF ADMIN ONLY ROUTES ---
                      }
                    </>
                  </>


                  :
                  //---  ROUTES FOR UNLOGGED USER --- 
                  <>
                    <Route path='/' element={<TemplateMarketplace />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/start-chat' element={<StartChat />} />
                    <Route path='/send-password-reset-code' element={<SendPasswordResetCode />} />
                    <Route path='/reset-password/:resetCode' element={<ResetPassword />} />
                  </>
                //---  END OF ROUTES FOR UNLOGGED USER --- 
              }

              <Route path='*' element={<PageNotFound />} />
              <Route path='/user/:username' element={<User />} />
              <Route path='/marketplace/template/:template_id' element={<ViewTemplate />} />
              <Route path='/enterprise/join' element={<EnterpriseJoin />} />
              <Route path='/enterprise-child/join' element={<EnterpriseChildJoin />} />
              <Route path='/chat/shared/:chat_id' element={<SharedChatView />} />

            </Routes>

            <Footer />
          </Router>
        </div>
        {user.role_id === 4
          &&
          <SetPaymentMethod socket={mySocket} />
        }
      </Elements>
    </GoogleOAuthProvider>
  );
}

export default App;
