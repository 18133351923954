import axios from "axios"
import moment from "moment-timezone"

export const thousandSeperator = (str) => {
    let value = parseInt(str)
    if (value < 0) {
        return `-₹${(value * -1).toLocaleString('en-US')}`
    } else {
        return `₹${value.toLocaleString('en-US')}`
    }
}

export const formatDateWithDay = (date) => {
    // create an array of days
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // get the day of the week (0-6)
    const dayIndex = date.getDay();

    // get the day of the week (string)
    const day = days[dayIndex];

    // get the day of the month (1-31)
    const dayOfMonth = date.getDate();

    // get the month (0-11)
    const monthIndex = date.getMonth();

    // get the month (string)
    const month = monthIndex < 9 ? `0${monthIndex + 1}` : monthIndex + 1;

    // get the year (4 digits)
    const year = date.getFullYear();

    // get the hours (0-23)
    let hours = date.getHours();

    // get the minutes (0-59)
    const minutes = date.getMinutes();

    // set AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // convert to 12-hour clock
    hours %= 12;
    hours = hours || 12;

    if (hours < 10) {
        hours = `0${hours}`
    }

    // add leading zero to minutes if necessary
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

    // create the formatted date string
    const dateString = `${day.substring(0, 3)}, ${dayOfMonth}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;

    return dateString;
}

export const calculateDateDistance = (date) => {
    const distance = moment(date).fromNow()
    return distance
}

export const formatDate = (date) => {
    // create an array of days
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // get the day of the week (0-6)
    const dayIndex = date.getDay();

    // get the day of the week (string)
    const day = days[dayIndex];

    // get the day of the month (1-31)
    const dayOfMonth = date.getDate();

    // get the month (0-11)
    const monthIndex = date.getMonth();

    // get the month (string)
    const month = monthIndex < 9 ? `0${monthIndex + 1}` : monthIndex + 1;

    // get the year (4 digits)
    const year = date.getFullYear();

    // get the hours (0-23)
    let hours = date.getHours();

    // get the minutes (0-59)
    const minutes = date.getMinutes();

    // set AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // convert to 12-hour clock
    hours %= 12;
    hours = hours || 12;

    if (hours < 10) {
        hours = `0${hours}`
    }

    // add leading zero to minutes if necessary
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

    // create the formatted date string
    const dateString = `${dayOfMonth}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;

    return dateString;
}

export const formatLocalDate = (date) => {
    // get the day of the month (1-31)
    const dayOfMonth = date.getDate();

    // get the month (0-11)
    const monthIndex = date.getMonth();

    // get the month (string)
    const month = monthIndex < 9 ? `0${monthIndex + 1}` : monthIndex + 1;

    // get the year (4 digits)
    const year = date.getFullYear();

    // get the hours (0-23)
    let hours = date.getHours();

    // get the minutes (0-59)
    const minutes = date.getMinutes();

    if (hours < 10) {
        hours = `0${hours}`
    }

    // add leading zero to minutes if necessary
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

    // create the formatted date string
    const dateString = `${year}-${month}-${dayOfMonth}T${hours}:${minutesStr}`;

    return dateString;
}

// export const getProfilePicture = (imageURL) => {
//     if (!imageURL || typeof imageURL !== "string") {
//         return "/images/default-profile.jpg"; // Return the default URL if imageURL is null, undefined, or not a string
//     }
//     else {
//         return imageURL
//     }
// }
export const getProfilePicture = async (imageURL) => {
    if (!imageURL || typeof imageURL !== "string" || imageURL === null) {
        return "/images/default-profile.jpg"; // Return the default URL if imageURL is null, undefined, or not a string
    }

    try {
        const response = await fetch(imageURL, { method: "HEAD" });
        if (response.ok) {
            return imageURL; // Return the original URL if it's valid and opens without errors
        }
    } catch (error) {
        console.error("Error validating image URL:", error);
    }

    return "/images/default-profile.jpg"; // Return the default URL if the imageURL is not valid or fails to open
}

export const validateBaseEmailTemplateBody = (htmlString) => {
    let isValid = true;
    let error = '';

    // Check for {{additionalStyles}} in the style tag
    const styleTagPattern = /<style[\s\S]*?>[\s\S]*?<\/style>/i;
    const styleTagMatch = htmlString.match(styleTagPattern);
    if (!styleTagMatch || !styleTagMatch[0].includes('{{additionalStyles}}')) {
        isValid = false;
        error += 'Missing {{additionalStyles}} in the style tag. ';
    }

    // Check for {{additionalContent}} in the body tag
    const bodyTagPattern = /<body[\s\S]*?>[\s\S]*?<\/body>/i;
    const bodyTagMatch = htmlString.match(bodyTagPattern);
    if (!bodyTagMatch || !bodyTagMatch[0].includes('{{additionalContent}}')) {
        isValid = false;
        error += 'Missing {{additionalContent}} in the body tag. ';
    }

    return { isValid, error: error.trim() };
};



