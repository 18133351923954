import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { MdNavigateNext, MdNavigateBefore, MdSearch } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { RiCloseLine } from 'react-icons/ri'
import { CgSortAz } from 'react-icons/cg'
import ManageChatAccess from '../components/ManageChatAccess'
import { GoKebabVertical } from 'react-icons/go'
import RenameChat from '../components/RenameChat'
import MyDropdown from '../components/MyDropdown'
import { Dropdown, Modal } from 'react-bootstrap'
import { BsFillChatSquareTextFill } from 'react-icons/bs'
import LoadingSkeleton from '../components/LoadingSkeleton'
import DeleteChat from '../components/DeleteChat'
import { PiShareFill } from 'react-icons/pi'
import ShareChat from '../components/ShareChat'
import { LiaTagsSolid } from "react-icons/lia";
import { AiFillTags } from "react-icons/ai";
import CustomModal from '../components/modals/CustomModal'
import { Helmet } from 'react-helmet'
import logo from '../assets/logo.svg'


const MyChats = () => {
    const [user] = useContext(UserContext)

    const [chatList, setChatList] = useState([])

    const [page, setPage] = useState(1)
    const [pageEnded, setPageEnded] = useState(false)

    const [selectedFilterOption, setSelectedFilterOption] = useState(0)
    const [selectedSortOption, setSelectedSortOption] = useState(0)

    const [fetchingChats, setFetchingChats] = useState(false)
    const [fetchingMoreChats, setFetchingMoreChats] = useState(false)

    const [usedTags, setUsedTags] = useState([])
    const [backupUsedTags, setBackupUsedTags] = useState([])
    const [usedTagSearchText, setUsedTagSearchText] = useState("")
    const [viewAllTagsModalState, setViewAllTagsModalState] = useState(false)
    const [selectedTagId, setSelectedTagId] = useState('0')
    const [loadingTags, setLoadingTags] = useState(false)

    const usedTagsContainerRef = useRef(null)
    const usedTagsRef = useRef(null)
    const [leftScroll, setLeftScroll] = useState(0)
    const [scrollWidth, setScrollWidth] = useState(1)

    const [searchTerm, setSearchTerm] = useState('')

    const navigate = useNavigate()

    const chatContainerEndRef = useRef(null)

    useEffect(() => {
        fetchMyChats()
    }, [selectedSortOption, selectedTagId])

    const fetchMyChats = (e) => {
        e?.preventDefault()
        setPageEnded(false)
        setPage(1)
        setFetchingChats(true)
        ProtectedAxios.post('/users/myChats', { user_id: user.user_id, selectedTagId: parseInt(selectedTagId), selectedFilterOption, searchTerm, selectedSortOption, page: 1 })
            .then(res => {
                if (res.data) {
                    if (e !== undefined) {
                        setSelectedTagId("0")
                        fetchMyUsedTags()
                    }
                    setChatList(res.data)
                    setFetchingChats(false)
                }
            })
            .catch(err => {
                setFetchingChats(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    useEffect(() => {
        fetchMyUsedTags()
    }, [])

    const fetchMyUsedTags = () => {
        setLoadingTags(true)
        ProtectedAxios.post('/users/allUsedTags', { user_id: user.user_id, selectedFilterOption, searchTerm })
            .then(res => {
                if (res.data) {
                    setUsedTags(res.data)
                    setBackupUsedTags(res.data)
                    setLoadingTags(false)
                }
            })
            .catch(err => {
                setLoadingTags(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }



    const loadMoreChats = () => {
        setFetchingMoreChats(true)
        let updatedPage = page + 1
        setPage(page => page + 1)
        ProtectedAxios.post('/users/myChats', { user_id: user.user_id, selectedTagId: parseInt(selectedTagId), selectedFilterOption, searchTerm, selectedSortOption, page: updatedPage })
            .then(res => {
                if (res.data) {
                    if (res.data.length < 4 || res.data.length === 0) {
                        setPageEnded(true)
                    }
                    setChatList(prev => [...prev, ...res.data])
                    setFetchingMoreChats(false)
                }
            })
            .catch(err => {
                setFetchingMoreChats(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }


    useEffect(() => {
        const handleScroll = () => {
            if (usedTagsContainerRef.current) {
                setScrollWidth(usedTagsContainerRef.current.scrollWidth - usedTagsContainerRef.current.clientWidth)
                setLeftScroll(usedTagsContainerRef.current.scrollLeft);
            }
        };

        usedTagsContainerRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            usedTagsContainerRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [usedTagsContainerRef]);

    const handleScroll = () => {
        if (usedTagsContainerRef.current) {
            setScrollWidth(usedTagsContainerRef.current.scrollWidth - usedTagsContainerRef.current.clientWidth)
            setLeftScroll(usedTagsContainerRef.current.scrollLeft);
        }
    };

    usedTagsContainerRef.current?.addEventListener('scroll', handleScroll);

    const shiftLeft = () => {
        usedTagsContainerRef.current.scrollLeft -= 150
        setLeftScroll(usedTagsContainerRef.current.scrollLeft)
    }

    const shiftRight = () => {
        usedTagsContainerRef.current.scrollLeft += 150
        setLeftScroll(usedTagsContainerRef.current.scrollLeft)
    }


    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                loadMoreChats()
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        const loadMoreButton = chatContainerEndRef.current;

        if (loadMoreButton) {
            observer.observe(loadMoreButton);
        }

        return () => {
            if (loadMoreButton) {
                observer.unobserve(loadMoreButton);
            }
        };
    }, [fetchingChats, fetchingMoreChats]);


    function handleChatClick(event, chatId) {
        const documentID = event.id;

        if (event.ctrlKey) {
            window.open(`/chat/${chatId}`, "_blank")
        } else {
            navigate(`/chat/${chatId}`)
        }
    }



    useEffect(() => {
        searchUsedTags()
    }, [usedTagSearchText])
    const searchUsedTags = () => {
        setBackupUsedTags(prev => {
            return usedTags.filter(tag => tag.tag.toLowerCase().includes(usedTagSearchText.toLowerCase()))
        })
    }


    return (
        <div className='container py-5'>
            <Helmet>
                <title>History - SageCollab</title>
            </Helmet>
            <div className='flexed-between mb-4 gap-2'>
                <h2 className='text-left w-100 title m-0'>Chat History</h2>
                <NavLink to="/start-chat" className='button button-new-chat'>New Chat</NavLink>
            </div>
            <div className='chat-search-container mb-4'>
                <form className='input-with-icon' onSubmit={fetchMyChats}>
                    <input
                        className='form-input'
                        type="text"
                        placeholder="search your chat"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    {searchTerm.length
                        ?
                        <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                        :
                        <></>
                    }
                </form>
                <select className='form-select' value={parseInt(selectedFilterOption)} onChange={e => setSelectedFilterOption(parseInt(e.target.value))}>
                    <option value={0}>title and content</option>
                    <option value={1}>title</option>
                    <option value={2}>content</option>
                </select>
            </div>

            {loadingTags
                ?
                <div className='d-flex gap-2 mt-3 px-4'>
                    <LoadingSkeleton type="tags" />
                    <LoadingSkeleton type="tags" />
                    <LoadingSkeleton type="tags" />
                </div>
                :
                <>
                    < div className={`used-tags-main ${leftScroll === 0 && 'scroll-start-reached'} ${(usedTagsContainerRef.current?.scrollWidth - usedTagsContainerRef.current?.clientWidth) - leftScroll === 0 && 'scroll-end-reached'}`}>
                        <div className='nav-container'>
                            <div className='icon-container icon-container-left'>
                                <MdNavigateBefore
                                    title='previous'
                                    style={{ display: `${leftScroll === 0 ? 'none' : 'block'}` }}
                                    onClick={() => { shiftLeft() }}
                                />
                            </div>
                            <div className='icon-container icon-container-right'>
                                <MdNavigateNext
                                    title='next'
                                    style={{ display: `${(scrollWidth - leftScroll === 0) ? 'none' : 'block'}` }}
                                    onClick={() => { shiftRight() }}
                                />
                                {usedTags.length > 2
                                    &&
                                    <AiFillTags
                                        title='view all tags'
                                        style={{ fontSize: "var(--size-m)" }}
                                        onClick={() => setViewAllTagsModalState(true)}
                                    />
                                }
                            </div>
                        </div>
                        <div className='used-tags-container' id='used-tags-container' ref={usedTagsContainerRef}
                            value={() => {
                                setTimeout(() => {

                                    return 'a'
                                }, 1000)
                            }}
                        >
                            <div className='used-tags' ref={usedTagsRef}>
                                {usedTags.map((tag, i) => {
                                    return (
                                        <div key={i} id={`used-tag-${tag.tag_id}`} title={`${tag.tag} - ${tag.use_count} chat(s)`} className={`used-tag ${selectedTagId === tag.tag_id && 'active'}`} onClick={() => setSelectedTagId(tag.tag_id)}>{tag.tag} ({tag.use_count})</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>
            }


            <div className={`my-3 mt-4 d-flex justify-content-between align-items-center ${chatList.length === 0 ? "disabled" : ""}`}>
                <div />
                <div className='d-flex flex-gap-3'>
                    <MyDropdown
                        disabled={chatList.length === 0}
                        position='left'
                        dropdownButton={
                            <div className='dropdown-link sort-dropdown dropdown'>
                                <button
                                    disabled={chatList.length === 0}
                                    className='dropdown-toggle'
                                >
                                    <CgSortAz />
                                    {selectedSortOption === 0
                                        ? "Newest"
                                        :
                                        selectedSortOption === 1
                                            ? "Oldest"
                                            :
                                            selectedSortOption === 2
                                                ? "Top Notes"
                                                : "New Notes"
                                    }
                                </button>
                            </div>
                        }
                        title="Sort chat history"
                    >
                        <div className={`dropdown-item ${selectedSortOption === 0 ? "active" : ""}`} onClick={() => { setSelectedSortOption(0) }}>Newest</div>
                        <div className={`dropdown-item ${selectedSortOption === 1 ? "active" : ""}`} onClick={() => { setSelectedSortOption(1) }}>Oldest</div>
                        <div className={`dropdown-item ${selectedSortOption === 2 ? "active" : ""}`} onClick={() => { setSelectedSortOption(2) }}>Top Notes</div>
                        <div className={`dropdown-item ${selectedSortOption === 3 ? "active" : ""}`} onClick={() => { setSelectedSortOption(3) }}>New Notes</div>
                    </MyDropdown>
                </div>
            </div>


            {
                fetchingChats
                    ?
                    <div className='prompt-list-container m-0'>
                        <LoadingSkeleton type='chat' />
                        <LoadingSkeleton type='chat' />
                        <LoadingSkeleton type='chat' />
                        <LoadingSkeleton type='chat' />
                    </div>

                    :
                    chatList.length === 0
                        ?
                        <div className='no-chats'>
                            <BsFillChatSquareTextFill />
                            {selectedTagId !== "0" || searchTerm.length > 0
                                ? <h3>No results found</h3>
                                :
                                <h3>No Chats</h3>
                            }
                            {selectedTagId === "0" && searchTerm.length === 0
                                &&
                                <p> Start chat with A.I. to see your chat history here</p>
                            }
                        </div>

                        :
                        <div className='prompt-list-container'>
                            {chatList.map((prompt, i) => {
                                return (
                                    <div className='prompt-main' key={i}>
                                        <div key={i} className='prompt' onClick={e => handleChatClick(e, prompt.chat_id)}>
                                            <div>
                                                {/* {prompt?.model_provider} */}
                                                <h4
                                                    title={
                                                        prompt.chat_name
                                                            ? prompt.chat_name
                                                            : prompt.prompt
                                                    }
                                                >
                                                    {prompt.chat_name
                                                        ? <>{prompt.chat_name.substring(0, 60)}{prompt.chat_name.length > 60 && '...'}</>
                                                        : <>{prompt.prompt.substring(0, 60)}{prompt.prompt.length > 60 && '...'}</>
                                                    }
                                                </h4>
                                                <p className='tags' title={prompt.tags}>{prompt.tags?.substring(0, 60)}{prompt.tags?.length > 60 && '...'}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                {
                                                    prompt.model_provider === "OPENAI" ? <img src="https://www.svgrepo.com/show/306500/openai.svg" className='w-s h-s' />
                                                        : prompt.model_provider === "GEMINI" ? <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" className='w-s h-s' />
                                                            : prompt.model_provider === "BEDROCK" ? <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                                                                : prompt.model_provider === "DEFAULT" && <img src={logo} className='w-m h-m' />
                                                }

                                                {prompt.is_sharing
                                                    &&
                                                    <div title="this chat is shared">
                                                        <PiShareFill className='edit-icon dark-icon w-s' />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <MyDropdown position='left'>
                                            <div className='dropdown-item'>
                                                <ShareChat
                                                    showAsLink={true}
                                                    chat_id={prompt.chat_id}
                                                    chatDetails={chatList[i]}
                                                    onChatDetailsChange={data => {
                                                        let chats = [...chatList]
                                                        chats[i].is_sharing = data.is_sharing
                                                        chats[i].shared_timestamp = data.shared_timestamp
                                                        setChatList(chats)
                                                    }}
                                                />
                                            </div>
                                            <div className='dropdown-item'>
                                                <ManageChatAccess showAsLink={true} chat_id={prompt.chat_id} prompt={prompt.prompt} />
                                            </div>
                                            <div className='dropdown-item'>
                                                <RenameChat chat_id={prompt.chat_id} prompt={prompt.prompt} filteredChatList={chatList} setFilteredChatList={setChatList} />
                                            </div>
                                            <div className='dropdown-item'>
                                                <DeleteChat chat_id={prompt.chat_id} prompt={prompt.prompt} chat_name={prompt.chat_name} filteredChatList={chatList} setFilteredChatList={setChatList} />
                                            </div>
                                        </MyDropdown>
                                    </div>
                                )
                            })}
                            {!pageEnded
                                ?
                                fetchingMoreChats
                                    ?
                                    <div className='prompt-list-container m-0'>
                                        <LoadingSkeleton type='chat' />
                                        <LoadingSkeleton type='chat' />
                                        <LoadingSkeleton type='chat' />
                                        <LoadingSkeleton type='chat' />
                                    </div>
                                    :
                                    <div ref={chatContainerEndRef} />
                                : ""
                            }
                        </div>
            }



            <CustomModal
                show={viewAllTagsModalState}
                onHide={setViewAllTagsModalState}
                title="All Used Tags"
                content={
                    <div>
                        <input placeholder='search your tags' value={usedTagSearchText} onChange={e => setUsedTagSearchText(e.target.value)} />

                        <div className='my-5 d-flex' >
                            <div className='used-tags flex-wrap w-100 overflow-auto gap-3 gap-md-2' style={{ maxHeight: "40dvh" }} >
                                {backupUsedTags.length === 0
                                    ?
                                    <div className='w-100 d-flex flex-column justify-content-center align-items-center gap-2'>
                                        <AiFillTags
                                            style={{ fontSize: "var(--size-m)" }}
                                        />
                                        <p className='text-secondary '>No tags found</p>
                                    </div>

                                    :
                                    backupUsedTags.map((tag, i) => {
                                        return (
                                            <div
                                                key={i}
                                                title={`${tag.tag} - ${tag.use_count} chat(s)`}
                                                className={`used-tag ${selectedTagId === tag.tag_id && 'active'}`}
                                                onClick={() => { setViewAllTagsModalState(false); setSelectedTagId(tag.tag_id); document.getElementById(`used-tag-${tag.tag_id}`).scrollIntoView({ offset: 20 }) }}
                                            >
                                                {tag.tag} ({tag.use_count})
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                }
                showFooter={false}
            />
        </div >
    )
}

export default MyChats